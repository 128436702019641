// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beach-news-js": () => import("./../src/pages/beach-news.js" /* webpackChunkName: "component---src-pages-beach-news-js" */),
  "component---src-pages-cleanup-events-js": () => import("./../src/pages/cleanup-events.js" /* webpackChunkName: "component---src-pages-cleanup-events-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-js": () => import("./../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-photo-gallery-js": () => import("./../src/pages/photo-gallery.js" /* webpackChunkName: "component---src-pages-photo-gallery-js" */),
  "component---src-pages-sponsorship-js": () => import("./../src/pages/sponsorship.js" /* webpackChunkName: "component---src-pages-sponsorship-js" */),
  "component---src-templates-news-post-js": () => import("./../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */)
}

